/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useContext} from 'react'
import {Dropdown1} from '../../content/dropdown/Dropdown1'
import {KTSVG} from '../../../helpers'
import {ItemMovimentacoes} from '../../../../app/components/ItemMovimentacoes'
import ConfigContext from '../../../../app/contexts/Config/ConfigContext'

type Props = {
  className: string
  color: string
  showToolBar?: boolean
  binario?: any
  debitos?: any
  diario?: any
  entradas?: any
  indicacao?: any
  residual?: any
  saidas?: any
  saques?: any
  unilevel?: any
  title: any
  desc?: any
}

const MixedWidget1: React.FC<Props> = ({
  className,
  color,
  showToolBar = false,
  binario,
  debitos,
  diario,
  entradas,
  indicacao,
  residual,
  saidas,
  saques,
  unilevel,
  title,
  desc,
}) => {
  const config = useContext(ConfigContext)

  return (
    <div className={`card ${className}`}>
      {/* begin::Body */}
      <div className='card-body p-0'>
        {/* begin::Header */}
        <div className={`px-9 pt-7 card-rounded h-275px w-100`} style={{backgroundColor: '#169226'}}>
          {/* begin::Heading */}
          <div className='d-flex flex-stack'>
            <h3 className='m-0 text-white fw-bold fs-3'>{title}</h3>
            <div className='ms-1'>
              {/* begin::Menu */}
              {showToolBar && (
                <>
                  <button
                    type='button'
                    className={`btn btn-sm btn-icon btn-color-white btn-active-white btn-active-color-${color} border-0 me-n3`}
                    data-kt-menu-trigger='click'
                    data-kt-menu-placement='bottom-end'
                    data-kt-menu-flip='top-end'
                  >
                    <KTSVG path='/media/icons/duotune/general/gen024.svg' className='svg-icon-2' />
                  </button>
                  <Dropdown1 />
                </>
              )}
              {/* end::Menu */}
            </div>
          </div>
          {/* end::Heading */}
          {/* begin::Balance */}
          <div className='d-flex text-center flex-column text-white pt-8'>
            <span className='fw-semibold fs-7'>{desc}</span>
            <span className='fw-bold fs-2x pt-1'>{config.moeda.prefixo} {entradas}</span>
          </div>
          {/* end::Balance */}
        </div>
        {/* end::Header */}
        {/* begin::Items */}
        <div
          className='shadow-xs card-rounded mx-9 mb-9 px-6 py-9 position-relative z-index-1 bg-body'
          style={{marginTop: '-100px'}}
        >
          {(color == 'primary' && (
            <>
              {/* begin::Item */}

              {config.binario && (
                <div className='d-flex align-items-center mb-6'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs041.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Description */}
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    {/* begin::Title */}
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>Binary</span>
                      <div className='text-gray-400 fw-semibold fs-7'>Binary Earnings</div>
                    </div>
                    {/* end::Title */}
                    {/* begin::Label */}
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>{config.moeda.prefixo} {binario}</div>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-5 svg-icon-success ms-1'
                      />
                    </div>
                    {/* end::Label */}
                  </div>
                  {/* end::Description */}
                </div>
              )}
              {/* end::Item */}
              {/* begin::Item */}

              {config.diario && (
                <div className='d-flex align-items-center mb-6'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen014.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Description */}
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    {/* begin::Title */}
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>Daily</span>
                      <div className='text-gray-400 fw-semibold fs-7'>Daily Earnings</div>
                    </div>
                    {/* end::Title */}
                    {/* begin::Label */}
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>{config.moeda.prefixo} {diario}</div>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-5 svg-icon-success ms-1'
                      />
                    </div>
                    {/* end::Label */}
                  </div>
                  {/* end::Description */}
                </div>
              )}
              {/* end::Item */}
              {/* begin::Item */}
              {config.indicacao && (
                <div className='d-flex align-items-center mb-6'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen049.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Description */}
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    {/* begin::Title */}
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Indication
                      </span>
                      <div className='text-gray-400 fw-semibold fs-7'>Earnings Indication</div>
                    </div>
                    {/* end::Title */}
                    {/* begin::Label */}
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>{config.moeda.prefixo} {indicacao}</div>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-5 svg-icon-success ms-1'
                      />
                    </div>
                    {/* end::Label */}
                  </div>
                  {/* end::Description */}
                </div>
              )}
              {/* end::Item */}
              {/* begin::Item */}
              {config.residual && (
                <div className='d-flex align-items-center mb-6'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/media/icons/duotune/general/gen032.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Description */}
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    {/* begin::Title */}
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                        Residual
                      </span>
                      <div className='text-gray-400 fw-semibold fs-7'>Residual Earnings</div>
                    </div>
                    {/* end::Title */}
                    {/* begin::Label */}
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>{config.moeda.prefixo} {residual}</div>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-5 svg-icon-success ms-1'
                      />
                    </div>
                    {/* end::Label */}
                  </div>
                  {/* end::Description */}
                </div>
              )}
              {/* end::Item */}
              {/* begin::Item */}
              {config.unilevel && (
                <div className='d-flex align-items-center mb-6'>
                  {/* begin::Symbol */}
                  <div className='symbol symbol-45px w-40px me-5'>
                    <span className='symbol-label bg-lighten'>
                      <KTSVG
                        path='/media/icons/duotune/abstract/abs027.svg'
                        className='svg-icon-1'
                      />
                    </span>
                  </div>
                  {/* end::Symbol */}
                  {/* begin::Description */}
                  <div className='d-flex align-items-center flex-wrap w-100'>
                    {/* begin::Title */}
                    <div className='mb-1 pe-3 flex-grow-1'>
                      <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                        Unilevel
                      </span>
                      <div className='text-gray-400 fw-semibold fs-7'>Unilevel Earnings</div>
                    </div>
                    {/* end::Title */}
                    {/* begin::Label */}
                    <div className='d-flex align-items-center'>
                      <div className='fw-bold fs-5 text-gray-800 pe-1'>{config.moeda.prefixo} {unilevel}</div>
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr066.svg'
                        className='svg-icon-5 svg-icon-success ms-1'
                      />
                    </div>
                    {/* end::Label */}
                  </div>
                  {/* end::Description */}
                </div>
              )}
              {/* end::Item */}
            </>
          )) || (
            <>
              {/* begin::Item */}
              <div className='d-flex align-items-center mb-6'>
                {/* begin::Symbol */}
                <div className='symbol symbol-45px w-40px me-5'>
                  <span className='symbol-label bg-lighten'>
                    <KTSVG path='/media/icons/duotune/finance/fin010.svg' className='svg-icon-1' />
                  </span>
                </div>
                {/* end::Symbol */}
                {/* begin::Description */}
                <div className='d-flex align-items-center flex-wrap w-100'>
                  {/* begin::Title */}
                  <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>Withdrawals</span>
                    <div className='text-gray-400 fw-semibold fs-7'>Total in withdrawals</div>
                  </div>
                  {/* end::Title */}
                  {/* begin::Label */}
                  <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{config.moeda.prefixo} {saques}</div>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr057.svg'
                      className='svg-icon-5 svg-icon-danger ms-1'
                    />
                  </div>
                  {/* end::Label */}
                </div>
                {/* end::Description */}
              </div>
              {/* end::Item */}
              {/* begin::Item */}
              <div className='d-flex align-items-center mb-6'>
                {/* begin::Symbol */}
                <div className='symbol symbol-45px w-40px me-5'>
                  <span className='symbol-label bg-lighten'>
                    <KTSVG path='/media/icons/duotune/finance/fin007.svg' className='svg-icon-1' />
                  </span>
                </div>
                {/* end::Symbol */}
                {/* begin::Description */}
                <div className='d-flex align-items-center flex-wrap w-100'>
                  {/* begin::Title */}
                  <div className='mb-1 pe-3 flex-grow-1'>
                    <span className='fs-5 text-gray-800 text-hover-primary fw-bold'>Debts</span>
                    <div className='text-gray-400 fw-semibold fs-7'>Total in debts</div>
                  </div>
                  {/* end::Title */}
                  {/* begin::Label */}
                  <div className='d-flex align-items-center'>
                    <div className='fw-bold fs-5 text-gray-800 pe-1'>{config.moeda.prefixo} {debitos}</div>
                    <KTSVG
                      path='/media/icons/duotune/arrows/arr057.svg'
                      className='svg-icon-5 svg-icon-danger ms-1'
                    />
                  </div>
                  {/* end::Label */}
                </div>
                {/* end::Description */}
              </div>
              {/* end::Item */}
            </>
          )}
        </div>
        {/* end::Items */}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {MixedWidget1}
